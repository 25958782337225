import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Image from '../components/image'
import SEO from '../components/seo'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`saunas`, `real`, `uk`]} />
    <h1>The UK Advocate of Genuine Sauna Bathing Facilities</h1>
      <p>Real Saunas has been established to promote sauna bathing in the UK. On this website we are collating information about the many saunas that are available to the public.</p>
      <p>We also hope to encourage people to make the most of the sauna experience.</p>
    <p>We’re finally in the process of rebuilding this website. Thanks to everyone who filled in our Visitor Survey the other year.</p>
      <p>Here's our classic articles:</p>
    <ul>
        <li><Link to="/info/naked-saunas-couples/">Naked Saunas for Couples</Link></li>
        <li><Link to="/info/areswimsuitscompulsory/">Are Swimsuits Compulsory?</Link></li>
        <li><Link to="/info/wikipedias-naked-facts/">Wikipedia's Naked Facts</Link></li>
        <li><Link to="/info/towelfriendlysauna/">Towel Friendly Saunas</Link></li>
    </ul>
      <p>Our inaugural location guides:</p>
      <ul>
          <li><Link to="/guide/near-shaftesbury-avenue-london/">Near Shaftesbury Avenue, London</Link></li>
          <li><Link to="/guide/manchester-hotel-saunas/">Manchester Hotel Saunas</Link></li>
      </ul>

  </Layout>
)

export default IndexPage
